
.user-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    margin-top: 10px;
    width: 90%; 
    margin: 2.5% 0px 5% 5%;
}

.user-container {
    display: flex;
    flex-direction: row;
}

.name {
    font-size: 32px;
    font-weight: bold;
    padding: 0px;
    align-self: flex-end; 
    font-family: var(--font-family-pretendard_semiBold);
}

.nick-name {
    font-size: 14px;
    font-family: var(--font-family-pretendard_regular);
    margin-left: 8px;
    align-self: flex-end; 
    padding-bottom: 5px;
}

.right-button {
    display: flex;
    padding-bottom: 5px;
    align-items: center;
    align-self: flex-end; 
    margin-right: 10px;
}
