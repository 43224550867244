body {
  margin: 0;
  padding: 0;
  height: auto; /* Allow the body to grow with content */
  min-height: 100vh; /* Ensure the body covers the viewport height */
  overflow-y: auto; /* Allow scrolling if content overflows */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1f1f1f;
  color: white;
  font: 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
